import Vue from 'vue'
import App from './App.vue'
import '@/assets/font/font.css'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import axios from 'axios'
Vue.use(ElementUI);
import 'element-ui/lib/theme-chalk/index.css';
Vue.prototype.axios = axios // 挂载到原型,可以在全局使用
Vue.config.productionTip = false
Vue.config.strict = false
// 设置浏览器标题
Vue.directive('title', {
  inserted: function (el, binding) {
    document.title = el.dataset.title
  }
})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
