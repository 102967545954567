<template>
	<!-- 首页 -->
	<div class="col-flex home" v-show='showip'>
		<div style="flex: 1;">
			<el-container>
				<!-- 公共头部 -->
				<Header title='index' />

				<!-- banner-个人中心部分 -->
				<div class='bannerbox'>
					<!-- {{infolist.banner}} -->
					<img :src="infolist.banner" alt="" class="background">
					<div class="centerbox just-flex" style="height: 100%;">
						<p></p>
						<div class='userbox flex columnflex'>
							<div class="atr">
								<img v-if="user.avatar_url!=''" :src="user.avatar_url" alt="">
								<img v-else src="../../src/assets/ara.jpg" alt="">
							</div>

							<p v-if="user.role_id ==1 ">教委：{{user.name}}</p>
							<p v-if="user.role_id ==2 ">教师：{{user.name}}</p>
							<p v-if="user.role_id ==3 ">审核教师：{{user.name}}</p>
							<p v-if="user.role_id ==4 ">作者：{{user.name}}</p>
							<p v-if="user.role_id ==5 ">校长：{{user.name}}</p>
							<p v-if="user.role_id ==6 ">副校长：{{user.name}}</p>
							<p v-if="user.role_id ==7 ">试用账号：{{user.name}}</p>
							<router-link to='/PersonCenter'><el-button type="primary">进入个人中心</el-button></router-link>


						</div>
					</div>
				</div>
				<!-- 左侧试卷 -->
				<div class="just-flex centerbox" style="margin-top: 22px;align-items: flex-start;">
					<div class="commonlist">
						<div class="comtitle just-flex">
							<aside>最新试卷</aside>
							<router-link to="Testlibrary">
								<p class="more">更多></p>
							</router-link>

						</div>


						<div class="" v-for="(item,index) in Exlist">
							<!-- {{item.id}} -->
							<router-link :to="{path:'/TestContent',query:{id:item.id}}"
								v-if='user.role_id != 3 && user.role_id != 4 && item.is_file == 2'>
								<div class="just-flex one">
									<p class="t">{{item.title}}</p>
									<i>{{item.create_time}}</i>
								</div>
							</router-link>
							<div v-if='user.role_id != 3 && user.role_id != 4 && item.is_file == 1'>
								<a :href="item.file" class="just-flex one">
									<p class="t">{{item.title}}</p>
									<i>{{item.create_time}}</i>
								</a>
							</div>
							<div class="just-flex one" @click="Nopermission"
								v-if='user.role_id == 3 || user.role_id == 4'>
								<p class="t">{{item.title}}</p>
								<i>{{item.create_time}}</i>
							</div>
						</div>

					</div>

					<!-- 右侧公告 -->
					<div class="commonlist commonlist2">
						<div class="comtitle just-flex">
							<aside>通知公告</aside>
							<!-- <router-link to="/">
								<p class="more">更多></p>
							</router-link> -->

						</div>

						<div class="" v-for="(item,index) in newslist">
							<router-link :to="{path:'/detail',query:{id:item.id}}">
								<div class="just-flex one">
									<p class="t">{{item.title}}</p>
									<i>{{item.create_time}}</i>
								</div>
							</router-link>
						</div>

					</div>

				</div>
				<!-- 公共尾部 -->
				<el-footer></el-footer>

			</el-container>
		</div>
		<!-- 尾部 -->
		<Footer></Footer>
	</div>
</template>

<script>
	// @ is an alias to /src
	import Header from '@/views/common/header.vue'
	import {
		setToken,
		getToken,
		removeToken
	} from '@/utils/setToken'
	import Footer from '@/views/common/footer.vue'
	import {
		articleList,
		getlistData,
		userInfo,
		schoolData
	} from '@/api/api'
	export default {
		name: 'HomeView',
		components: {
			Header,
			Footer
		},
		mounted() {

			
			if (this.$route.query.school_id) {
				setToken('school_id', this.$route.query.school_id)
				removeToken('token')
				this.$router.push('/login');
			} else {
				console.log()
				// setToken('school_id', 1)
			}
			this.setList()
			this.getExList()
			this.userInfo()
			console.log(getToken('school_id'))

			this.getip()
		},
		data() {
			return {
				newslist: [],
				Exlist: [],
				user: {},
				infolist: {},
				nowip: '',
				showip: false
			}
		},
		methods: {
			getip() {
				this.axios({
					url: 'https://api.ipify.org'
				}).then(res => {
					this.nowip = res.data;
					this.schoolData()
				})
			},
			Nopermission() {
				this.$message({
					message: '当前身份无权限查看',
					type: 'warning'
				})
			},
			setList() {
				articleList({}).then(res => {
					console.log(res.data.data)
					this.newslist = res.data.data.data
				})
			},
			schoolData() {
				var id = getToken('school_id')
				if (!id) {
					this.$router.push('/login');
				} else {
					schoolData({
						id: getToken('school_id')
					}).then(res => {
						console.log(res.data.data)
						this.infolist = res.data.data
						console.log(this.infolist.ip, this.nowip)
						if ((this.infolist.ip != this.nowip) && id != 0) {
							if(this.infolist.ip == ''){
								this.showip = true
							}else{
								this.$message({
									message: '无效的登录IP',
									type: 'warning'
								})
								this.$router.push('/login');
							}
							
						} else {
							this.showip = true
						}
					})
				}

			},

			getExList() {
				getlistData({}).then(res => {
					console.log(res.data.data)
					this.Exlist = res.data.data.data
				})
			},
			userInfo() {
				userInfo({}).then(res => {
					console.log(res.data.data)
					this.user = res.data.data
					// this.Exlist  = res.data.data.data
				})
			},

		}
	}
</script>
<style type="text/css">
	.background {
		width: 100%;
	}

	.bannerbox {
		position: relative;
		height: 400px;
	}

	.bannerbox .background {
		position: absolute;
		min-width: 1200px;
		width: 100%;
		/* z-index: -1; */
		height: 400px;
	}

	.userbox {
		width: 173px;
		height: 217px;
		background: rgba(255, 255, 255, .5);
		/* opacity: 0.5; */
		border-radius: 8px;
		position: relative;
		z-index: 9;

	}

	.atr img {
		width: 72px;
		height: 72px;
		border-radius: 50%;
		margin-top: 32px;
	}

	.userbox p {
		margin: 18px 0 15px;
		color: #fff;
	}

	.comtitle {
		margin-bottom: 9px;
	}

	.comtitle aside {
		width: 72px;
		height: 18px;
		font-size: 18px;
		font-family: Source Han Sans CN;
		font-weight: 400;
		color: #000000;
		/* font-weight: 600; */
		line-height: 18px;
		border-bottom: 3px solid #409EFF;
		padding-bottom: 13px;
	}

	.more {
		font-size: 14px;
		font-family: Source Han Sans CN;
		font-weight: 400;
		color: #909399;
	}

	.commonlist {
		width: 739px;
	}

	.commonlist2 {
		flex: 1;
		margin-left: 29px;
	}

	.one {
		padding: 9px 0;
	}

	.commonlist .t {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		-o-text-overflow: ellipsis;
		width: 315px;

	}

	.commonlist2 .t {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		-o-text-overflow: ellipsis;
		width: 209px;

	}

	.commonlist i {
		color: #C0C4CC;
		font-weight: 400;
	}

	.home a {
		color: #333;
	}

	.home {
		min-height: 100vh;
	}
</style>